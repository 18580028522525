<template>
  <el-container>
    <el-aside width="200px">
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        :default-openeds="openList">
        <el-submenu index="systemMenu">
          <span slot="title">{{$t('home.page.head.menu.systemMain')}}</span>
          <el-menu-item index="/system/sub/customer">{{$t('home.page.head.menu.customer')}}</el-menu-item>
          <el-menu-item index="/system/sub/dept">{{$t('home.page.head.menu.dept')}}</el-menu-item>
          <el-menu-item index="/system/sub/users">{{$t('home.page.head.menu.users')}}</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import router from '@/router'

export default {
  name: 'systemMain',
  data () {
    return {
      openList: ['systemMenu', '/system/sub/customer']
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      router.push({ path: key.toString() })
    }
  }
}
</script>

<style scoped>

.el-container {
  top: 0;
  position: relative;
  margin: 0 0 0 0 !important;
  padding-top: 0;
}

.el-main {
  margin: 0 0 0 0 !important;
  padding-top: 0;
}

</style>
